import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class CommercialPropertyInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Commercial Property Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Commercial property is a key part of most Louisiana based businesses' operations. Commercial Property Insurance helps cover the costs for repair and replacement costs. Contact us for an insurance quote!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Commercial Building Complex" />
        <Callout
          title="Commercial Property Insurance"
          content="Property is a key part of most Louisiana based businesses' operations. However, maintaining and minimizing the risk associated with it can be a challenge. When property is damaged and unexpected inventory is loss, it can significantly affect your business’s operations. Commercial Property Insurance helps cover the costs of the related repair and replacement costs to get your company back going."
        />
        <div className="site-body page-content">
          <h2>What Commercial Property Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Company Buildings.</strong> This covers the repair of the structure and will sometimes include installed fixtures and machinery.</li>
          <li><strong>Contents of Buildings.</strong> This normally includes things that can be easily removed such as inventory, office equipment and supplies.</li>
          <li><strong>Other Property.</strong> This includes items that are left by someone else in your care or control.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="Most Louisiana commercial property policies do not include flood damage and it must be added separately."
            source=""
          />
          <h2>What Commercial Property Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li>Money and securities, accounts, bills and food stamps.</li>
          <li>Vehicles, aircraft or watercraft (with some exceptions).</li>
          <li>Flood Damage.</li>
          <li>Earthquake Damage.</li>
          <li>Animals other than stock.</li>
          <li>Land, piers, wharves or docks.</li>
          <li>Crops, grain or hay located outside.</li>
          <li>The cost of excavations, grading or backfilling.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Commercial Property Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default CommercialPropertyInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "commercial-property-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-18.jpg" }) {
      ...mainBg
    }
  }
`;
